@import '../../../../assets/styles/global.scss';

@media all {
    .page-break {
      display: none;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  }

@media screen {
	.short-report-template {
		visibility: hidden;
	}
}

.short-report-template {
    position: absolute;
    .report-header {
        display: flex;
        flex-direction: column;
        p {
            margin: 6px 0;
        }
    }
    p {
        font-size: 13px;
        line-height: 22px;
        color: black;
        b {
            font-size: 13.5px;
        }
    }
    h4 {
        font-size: 19px;
    }
    .kpi-audit {
        margin-bottom: 24px;
        ul {
            margin-left: 48px;
            margin-bottom: 8px;
            margin-top: 8px;
            .checked {
                list-style-type: lower-alpha;
            }
            .unchecked {
                list-style-type: circle;
            }
            li {
                font-size: 13px;
                line-height: 22px;
                color: black;
            }
        }
    }
    .summary-item-column {
        height: 340px;
        width: 750px;
    }
}