@import '../../../assets/styles/base/_variables';

#footer {
    padding: 25px 30px;
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba($grey-400, .3);
    display: grid;
    grid-template-columns: 1fr;

    .content {
        display: grid;
        grid-template-columns: 1fr;
        align-content: center;
        justify-items: center;
        gap: 7px;
    }

    p {
        text-align: center;
        font-size: 13px;
    }
}