@import '../../../../assets/styles/global.scss';

.kpisSummary-content {
    padding: 20px 30px 30px;

    .summary-table {
        display: grid;
        >.single-kpi-row__afcac {
            display: grid;
            padding: 20px;
            gap: 10px;
            border: solid 1px #ddd;
            margin-bottom: 15px;
            grid-template-rows: 370px;
        }
        .single-kpi-row {
            display: grid;
            padding: 20px;
            gap: 10px;
            border: solid 1px #ddd;
            margin-bottom: 15px;
            .kpi-label {
                p {font-size: 13px;}
            }
            .summary-item-column {
                height: 300px;
                padding-bottom: 15px;
                transform: translate(-50px)
            }
            .stats-wrapper {
                display: grid;
                grid-template-columns: 1fr;
                gap: 10px;
                .ant-card-body {
                    padding: 8px 20px !important;
                    border-color: $stroke-color !important;
                }
                .ant-statistic-content {
                    font-size: 19px !important;
                }
            }
        }
    }
    .bar-wrapper >div{
        transform: translateX(-30px);
    }
    svg {
        width: 100% !important;
    }
    .ant-statistic-title {
        margin-bottom: 0 !important;
    }
    .ant-statistic-content-value {
        font-weight: bold;
        font-size: 18px;
        color: $primary;
    }
    .green .ant-statistic-content-value{
        color: #43a047;
    }
    .yellow .ant-statistic-content-value{
        color: #fdd835;
    }
    .red .ant-statistic-content-value{
        color: #f44336;
    }
}


.graphics {
    display: grid;
    grid-template-areas: 'a1 a1' 'a2 a3';
    grid-auto-columns: 1fr;
    gap: 10px;
    >.stats {
        grid-area: a1;
        display: flex; 
        align-items: center;
        gap: 7px;
    }
    >.global-charts {
        grid-area: a2;
        position: relative;
        border: solid 1px $stroke-color;
        padding: 10px;
        height: 470px;
        .ranking_text {
            position: absolute;
            left: 40%;
        }
    }
    >.bar-global {
        grid-area: a3;
    }
    >#small-bar {
        height: 120px;
    }
}

.global-states {
    .ant-card-body {
        padding: 8px 20px !important;
        border-color: $stroke-color !important;
    }
    .ant-statistic-content {
        font-size: 19px !important;
    }
}

.states-performances {
    display: grid;
    grid-template-columns: 20% 80%;
    gap: 10px;
    >.states-listing {
        height: 490px;
        overflow-y: scroll;
        border: solid 1px $stroke-color;
        padding: 10px;
        >div {
            display: flex;
            align-items: center;
            gap: 5px;
            --gap: 5px;
            cursor: pointer;
            transition: all .2s ease-in-out;
            padding: 3px;
            &:hover {
                >p {
                    font-weight: bold;
                    font-size: 15px;
                }
            }
            &.selected {
                background-color: $primary;
                >p {
                    font-weight: bold;
                    color: $white;
                }
            }
        }
    }
}

.states-performances-no {
    grid-template-columns: 100% !important;
}

.ant-tabs-tab-btn {
    font-weight: 600 !important;
}