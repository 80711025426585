@font-face {
    font-family: 'Open Sans Semi Black';
    src: url('../../fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans Black Italic';
    src: url('../../fonts/OpenSans-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans Extra Black';
    src: url('../../fonts/OpenSans-ExtraBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans Black';
    src: url('../../fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans Italic';
    src: url('../../fonts/OpenSans-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans Light';
    src: url('../../fonts/OpenSans-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans Light Italic';
    src: url('../../fonts/OpenSans-LightItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans SemiBold';
    src: url('../../fonts/OpenSans-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans SemiBoldItalic';
    src: url('../../fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
}
